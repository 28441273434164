body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: mainFont;
  src: url(assets/fonts/Segoe\ UI.woff)
}

.mainLogo {
  width: 50%;
  padding-top: 70px;
}

.menuLogo {
  width: 10%;
  padding-top: 30px;
}

.buttonForm{
  background-color: #1AB394 !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 0% !important;
  font-family: mainFont !important;
  font-size: 14px !important;
}

.buttonMenu{
  background-color: #1AB394 !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 0% !important;
  font-family: mainFont !important;
  font-size: 14px !important;
  margin-right: 10px;
}

.buttonMenu1{
  background-color: white !important;
  color: #1AB394 !important;
  border-radius: 0% !important;
  border: solid 1px #1AB394 !important;
  font-family: mainFont !important;
  font-size: 14px !important;
  margin-right: 10px;
}

.generalFont{
  font-family: mainFont !important;
  color: #707070;
  font-weight: bold;
  font-size: 13px;
}

.generalTitle{
  font-family: mainFont !important;
  color: #707070;
  font-weight: bold;
  font-size: 17px;
}

.generalFont1{
  font-family: mainFont !important;
  color: #707070;
  font-size: 13px;
}

.buttonFont{
  font-family: mainFont !important;
  color: white;
  font-size: 13px;
}

.generalTitle2{
  font-family: mainFont !important;
  color: #1AB394;
  font-size: 15px;
}

.surveyBG{
  background-color: rgb(231, 231, 231) !important;
}

.generalColor{
  color: #1AB394;
  text-decoration: none;
}

.generalColor:hover{
  color: #1AB394;
  text-decoration: none;
}